/**
 * @name: 小程序管理-banner轮播图管理接口文件
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 小程序管理-banner轮播图管理接口文件
 * @update: 2024-07-18 14:45
 */
import api from '@/request/api'
import {IPageRes} from "@/apis/page";
import { IMiniBanner, IMiniBannerParams } from './types';

export const miniBannerQueryApi = (params: IMiniBannerParams) => api.get<IPageRes<IMiniBanner[]>>('/admin/banner/query', params)

export const miniBannerSwitchStatusApi = (id: string) => api.postJ('/admin/banner/updateStatus', {id})

export const miniBannerDelApi = (id: string) => api.get('/admin/banner/remove/' + id)

export const miniBannerCreateApi = (data: Partial<IMiniBanner>) => api.postJ('/admin/banner/create', data)

export const miniBannerModifyApi = (data: Partial<IMiniBanner>) => api.postJ('/admin/banner/modify', data)
