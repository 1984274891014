
/**
 * @name: 小程序管理-banner轮播图管理
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 小程序管理-banner轮播图管理
 * @update: 2024-07-18 14:45
 */
import { Vue, Component } from "vue-property-decorator"
import {
  miniBannerQueryApi,
  miniBannerSwitchStatusApi,
  miniBannerDelApi,
  miniBannerCreateApi,
  miniBannerModifyApi,
} from '@/apis/mini/banner'
import {
  IMiniBanner,
  IMiniBannerParams
} from '@/apis/mini/banner/types'
import config from '@/config'
import { checkSort } from '@/constants/validators'
import type { ICrudOption } from "@/types/m-ui-crud"

@Component({})
export default class miniBanner extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IMiniBanner[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IMiniBannerParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IMiniBanner> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    searchBox: false,
    column: [
      {
        "label": "序号",
        type: 'index',
        width: 50,
        addHide: true,
        editHide: true,
        "align": "center"
      },
      {
        "label": "图片",
        "prop": "pic",
        "align": "center",
        "type": "image",
        addSlot: true,
        editSlot: true,
        imgPrefix: config.downloadUrl,
        rules: [
          {
            required: true,
            message: '请上传图片',
            trigger: 'blur'
          }
        ],
      },
      {
        "label": "排序",
        "prop": "sort",
        "align": "center",
        rules: [
          {
            required: true,
            message: '请输入排序',
            trigger: 'blur'
          },
          {
            validator: checkSort,
            trigger: 'blur'
          },
          {
            validator: (rule: any, value: string, callback: Function) => {
              if (parseInt(value) > 99) {
                return callback(new Error('排序最大值99'))
              }
              callback()
            },
            trigger: 'blur'
          }
        ]
      },
      {
        "label": "状态",
        "prop": "status",
        "align": "center",
        "type": "select",
        slot: true,
        addHide: true,
        editHide: true
      },
      {
        "label": "创建时间",
        "prop": "addTime",
        "align": "center",
        addHide: true,
        editHide: true
      },
    ]
  }

  getList () {
    this.tableLoading = true

    miniBannerQueryApi(this.queryParam).then(e => {
      if (e && e.data) {
        this.tableData = e.data.list
        this.tableTotal = e.data.total
        this.tableLoading = false
      }
    })
  }

  switchStatus (row: IMiniBanner) {
    miniBannerSwitchStatusApi(row.id).then(e => {
      if (e && e.data) {
        this.getList()
        this.$message.success('操作成功!')
      }
    })
  }

  rowSave (form: IMiniBanner, done: Function, loading: Function) {
    miniBannerCreateApi(form).then(e => {
      if (e && e.data) {
        this.getList()
        this.$message.success('操作成功!')
        done()
      }
    }).finally(() => {
      loading(0)
    })
  }

  rowEdit (form: IMiniBanner, done: Function, loading: Function) {
    miniBannerModifyApi(form).then(e => {
      if (e && e.data) {
        this.getList()
        this.$message.success('操作成功!')
        done()
      }
    }).finally(() => {
      loading(0)
    })
  }

  rowDel (row: IMiniBanner) {
    miniBannerDelApi(row.id).then(e => {
      if (e && e.data) {
        this.getList()
        this.$message.success('操作成功!')
      }
    })
  }

  beforeUpload (file: File) {
    const fileType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
    const fileSize = file.size / 1024 < 500;

    if (!fileType) {
      this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
    }
    if (!fileSize) {
      this.$message.error('上传图片大小不能超过 500KB!');
    }
    return fileType && fileSize;
  }

  created () {
    this.getList()
  }
}
